import React, { useContext, useState, useEffect, useMemo } from 'react';
import AppContext from '../../../App/AppContext';
import { SecondaryButton, DialogContents, DialogTitles, UploadWithBadge } from '../../../Core/FormInput';
import '../../../Core/Modal/model.css';
import Grid from '@material-ui/core/Grid';
import { DataGrid, GridOverlay } from '@material-ui/data-grid';
import { getPartReqs, issuePartsQty } from '../../../Core/Service/partRequisitions-service';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { find, some } from 'lodash';
import Button from '@material-ui/core/Button';
import '../../technician/technician-app/TechnicianStyle.scss';
import { CustomeSignPad } from '../../../Core/SignaturePad';
import DialogComp from '../.././../Core/Modal/dialogModal';
import { CircularProgress } from '@material-ui/core';
import ConfirmationModal from '../../../Core/Controls/Dialog/ConfirmationModal';
import { useWindowSize } from '../../../Core/Controls/ScreenResolution';
import PartReqPhotoModal from '../../technician/technician-app/PartReqPhotoUploadmodal';
import InfoIcon from '@material-ui/icons/Info';
import PartReqOtherInfo from '../../technician/technician-app/RequestorApproverIssuerInfo';
import { postPhotosByPartReqID } from '../../../Core/Service/partRequisitions-service';

const PartRequisitionsIssueQuantity = (props) => {
    const { hideTopNavAndMenu, showToast, portalSettings, userRoles } = useContext(AppContext);
    const urlParams = new URLSearchParams(props.location.search.toLowerCase());
    const navigateToDashboard = urlParams.get('navigate') === 'true';
    const fromStep = urlParams.get('fromstep') === 'true';
    const Id = props.match.params.jobId;
    const [btnLoader, setBtnLoader] = useState({ btn1: false, btn2: false });

    const [state, setState] = useState({
        needQtyIssue: false,
        rows: [],
        technicianJobID: props.match.params?.technicianJobId,
        wipDetails: [],
        stockList: [],
        technician: [],
        statusList: [],
        showSignature: false,
        errors: {},
        showLoader: true,
        checkApprover: null,
        checkStatus: null,
        showConfirmModal: false,
        changedRecordIds: [],
        createdUserID: null,
        ownUser: true
    });
    const isAuditor = userRoles.includes('auditor');

    const onIssueQtyChange = (e) => {
        const vl = e.target.value;
        const nm = e.target.name;
        const rw = find(state.rows, (r) => r.id === +e.target.dataset.rowid);
        let temp = [...state.changedRecordIds];
        let findIDExist = temp.find((a) => a === +e.target.dataset.rowid);
        if (!findIDExist) {
            temp.push(+e.target.dataset.rowid);
        }
        if (rw && nm == 'desc') {
            rw.desc = vl;
            setState((st) => ({ ...st, rows: [...st.rows], changedRecordIds: temp }));
        }
        if (rw && nm == 'qnIssuing') {
            rw.qnIssuing = vl;
            setState((st) => ({ ...st, rows: [...st.rows], changedRecordIds: temp }));
        }
        if (rw && nm == 'partReqStockStatusId') {
            rw.partReqStockStatusId = vl;
            setState((st) => ({ ...st, rows: [...st.rows], changedRecordIds: temp }));
        }
        if (rw && nm == 'issueStatusID') {
            rw.issueStatusID = vl;
            setState((st) => ({ ...st, rows: [...st.rows], changedRecordIds: temp }));
        }
        if (rw && nm == 'supplier') {
            rw.supplier = vl;
            setState((st) => ({ ...st, rows: [...st.rows], changedRecordIds: temp }));
        }
    };

    const [windowWidths, windowHeight] = useWindowSize();

    const onIssueQtyBlur = (e) => {
        const vl = e.target.value;
        const rw = find(state.rows, (r) => r.id === +e.target.dataset.rowid);
        if (vl < 0 || rw.maxIssuing < vl) {
            rw.qnIssuing = Math.max(0, Math.min(rw.maxIssuing, vl));
            setState((st) => ({ ...st, rows: [...st.rows] }));
        }
    };

    const RenderData = ({ name, img, date }) => {
        return (
            <Grid
                container
                item
                alignItems="center"
                onClick={() => {
                    showModal(img, date);
                }}
                style={{ cursor: 'pointer' }}
            >
                <Grid item style={{ color: `${img ? 'green' : 'black'}` }}>
                    {name}
                </Grid>
                {/* <Grid item style={{ display: 'table' }}>
                    <img src={img} width="40px" style={{ display: 'table-cell', verticalAlign: 'middle' }} />
                </Grid> */}
            </Grid>
        );
    };

    const onImagemodalClose = (res) => {
        setState((st) => ({ ...st, showImage: false, partId: null, desc: '' }));
        makeEmptyPhotos('oldPhoto');
        res && pullWorkflowAndUpdateState();
    };
    const [data, setData] = useState({ showInfo: false });
    const setShowInfo = (val) => {
        setData((st) => ({
            ...st,
            showInfo: true,
            requestor: val.requestor,
            requestorSign: val.requestorSignature,
            requestorDate: val.requestedTime,
            issuer: val.issuer,
            issuerSign: val.issuerSignature,
            issuerDate: val.issuedTime,
            approver: val.approver,
            approverSign: val.approverSignature,
            approverDate: val.approvedTime
        }));
    };
    const columns = [
        {
            field: 'desc',
            headerName: 'Part',
            width: 400,
            renderCell: (params) => {
                return <input name="desc" id="desc" data-rowid={params.row.id} onChange={onIssueQtyChange} value={params.value} className="pa-4" />;
            }
        },
        { field: 'qn', headerName: 'Qty', width: 90 },
        { field: 'qnIssued', headerName: 'Issued', width: 117 },

        {
            field: 'qnIssuing',
            headerName: 'Issuing',
            width: 120,
            renderCell: (params) => {
                return (
                    <input
                        name="qnIssuing"
                        id="qnIssuing"
                        type="number"
                        disabled={params.row.disabled}
                        data-rowid={params.row.id}
                        onChange={onIssueQtyChange}
                        onBlur={onIssueQtyBlur}
                        value={params.value}
                        min={0}
                        max={params.row.maxIssuing}
                        className="pa-4"
                    />
                );
            }
        },
        {
            field: 'issueStatusID',
            headerName: 'Issue status',
            width: 180,
            renderCell: (params) => {
                return (
                    <div style={{ position: 'relative' }}>
                        {!params.row.issueStatusID && <span style={{ position: 'absolute', top: -11, right: -6, color: 'red' }}>*</span>}
                        <select
                            error
                            name="issueStatusID"
                            id="issueStatusID"
                            className="pa-4 "
                            disabled={params.row.disabled}
                            data-rowid={params.row.id}
                            onChange={onIssueQtyChange}
                        >
                            <option value="" selected>
                                Choose here
                            </option>
                            {state.statusList.map((option) => {
                                let isSelected = option.value == params.row.issueStatusID;
                                return (
                                    <option key={option.value} value={option.value} selected={isSelected}>
                                        {option.text}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                );
            }
        },
        {
            field: 'supplier',
            headerName: 'Supplier',
            width: 300,
            renderCell: (params) => {
                return <input name="supplier" id="supplier" data-rowid={params.row.id} onChange={onIssueQtyChange} value={params.value} className="pa-4" />;
            }
        },
        {
            field: '',
            headerName: '',
            width: 119,
            sortable: false,
            renderCell: (params) => (
                <div style={{ margin: '20px 0px 0px 9px', cursor: `${params.row.photosUploaded >= 0 ? 'pointer' : ''}` }}>
                    <UploadWithBadge
                        state={params.row.photosUploaded > 0 || 0}
                        count={params.row.photosUploaded || 0}
                        onClick={() =>
                            params.row.photosUploaded >= 0 && setState((st) => ({ ...st, partId: params.row.id, desc: params.row.desc, showImage: true }))
                        }
                    />
                    <InfoIcon className="doneIcon" onClick={() => setShowInfo(params.row)} />
                </div>
            )
        }
    ];

    const new_columns = useMemo(() => {
        const tempCols = [...columns];
        if (state.checkStatus) {
            tempCols.splice(7, 0, { field: 'partReqStatusDes', headerName: 'Status', width: 130 });
        }
        if (portalSettings.portalSettingDisplayPartStockStatus) {
            tempCols.splice(4, 0, {
                field: 'partReqStockDes',
                headerName: 'Stock',
                width: 140,
                renderCell: (params) => {
                    return (
                        <div style={{ position: 'relative' }}>
                            {!params.row.partReqStockStatusId && <span style={{ position: 'absolute', top: -11, right: -6, color: 'red' }}>*</span>}

                            <select
                                error
                                name="partReqStockStatusId"
                                id="partReqStockStatusId"
                                className="pa-4 "
                                disabled={params.row.disabled}
                                data-rowid={params.row.id}
                                onChange={onIssueQtyChange}
                            >
                                <option value="" selected>
                                    Choose here
                                </option>
                                {state.stockList.map((option) => {
                                    let isSelected = option.value == params.row.partReqStockStatusId;
                                    return (
                                        <option key={option.value} value={option.value} selected={isSelected}>
                                            {option.text}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    );
                }
            });
        }
        return tempCols;
    }, [columns]);

    const pullWorkflowAndUpdateState = async () => {
        let result = await getPartReqs(Id, state.createdUserID, 'A', state.technicianJobID);
        if (result.success) {
            const ops1 = result.data.partReqStockStatus.map((rr) => ({ value: rr.partReqStockStatusId, text: rr.partReqStockStatusDescriptions }));
            const ops = result.data.partReqIssueStatus.map((rr) => ({ value: rr.partReqIssueStatusID, text: rr.partReqIssueStatusDescription }));
            const rows = result.data.partReqs.map((r) => ({
                id: r.partReqID,
                desc: r.partReqPartDescription,
                qn: r.partReqQuantity,
                qnIssued: r.partReqIssuedQuantity,
                qnIssuing: 0,
                maxIssuing: r.partReqQuantity - r.partReqIssuedQuantity,
                disabled: r.partReqQuantity === r.partReqIssuedQuantity,
                partReqStatusDes: r.partReqStatusDescription,
                technicianJobID: r.partReqTechnicianJobID,
                requestor: r.requestor,
                requestedTime: r.requestedTime,
                approver: r.approver,
                issuer: r.issuer,
                issuedTime: r.issuedTime,
                approvedTime: r.approvedTime,
                issueStatus: r.issueStatus,
                issueStatusID: r.issueStatusID,
                partReqStockStatusId: r.partReqStockStatusId,
                requestorSignature: r.requestorSignature,
                approverSignature: r.approverSignature,
                issuerSignature: r.issuerSignature,
                supplier: r.supplier,
                photosUploaded: r.photosUploaded,
                Photos: [],
                oldPhoto: []
            }));
            setState((st) => {
                const nst = { ...st };
                nst.rows = rows;
                nst.showLoader = false;
                nst.stockList = ops1;
                // nst.requestorSignature = result.data.partReqs[0]?.requestorSignature;
                // nst.requestorSignature = result.data.partReqs[0]?.requestorSignature;
                // nst.approverSignature = result.data.partReqs[0]?.approverSignature;
                // nst.issuerSignature = result.data.partReqs[0]?.issuerSignature;
                // nst.IssuerSignature = result.data.partReqs[0]?.issuerSignature;
                nst.needQtyIssue = some(rows, (r) => r.qn > r.qnIssued);
                nst.wipDetails = result.data.wipDetails[0];
                nst.technician = result.data.technician;
                nst.statusList = ops;
                nst.checkApprover = some(result.data.partReqs, (r) => r.approver);
                nst.checkStatus = some(result.data.partReqs, (r) => r.partReqStatus);
                // if (!st.technicianNotesJobID) {
                //     nst.technicianNotesJobID = result.data?.technician[0]?.technicianJobID;
                // }
                if (st.createdUserID == null) {
                    nst.createdUserID = result.data.technician[0]?.createdUserID;
                    nst.technicianJobID = result.data.technician[0]?.technicianJobID;
                    nst.createdUserID = result.data.technician[0]?.createdUserID;
                    nst.ownUser = result.data.technician[0]?.ownUser == 'Y';
                }
                return nst;
            });
        } else {
            showToast(result.message);
        }
    };
    const validations = () => {
        const { issuerSignature } = state;
        let formIsValid = true;
        let errors = {};
        let isError = false;
        let errorMsg = '';
        if (!issuerSignature) {
            // errors.issuerSignature = 'Issuer signature is required';
            // formIsValid = false;
            isError = true;
            errorMsg = 'Issuer signature is required';
        }
        state.rows.forEach((r) => {
            if (!r.disabled && !r.issueStatusID) {
                isError = true;
                errorMsg = 'Please select Issue status';
            }
            if (portalSettings.portalSettingDisplayPartStockStatus && !r.disabled && !r.partReqStockStatusId) {
                isError = true;
                errorMsg = 'Please select stock';
            }
        });
        if (isError) {
            errors.isError = errorMsg;
            formIsValid = false;
        }
        setState((st) => ({ ...st, errors: errors }));
        return formIsValid;
    };

    const handelSubmit = () => {
        if (validations()) {
            setState((st) => ({ ...st, showConfirmModal: true }));
        }
    };
    const updateIssuedQuantity = async (mode) => {
        mode == 'save' ? setBtnLoader((st) => ({ ...st, btn2: true })) : setBtnLoader((st) => ({ ...st, btn1: true }));
        const data = {
            signature: state.issuerSignature,
            mode: mode,
            quantityIssueAgainstPartReqs: state.changedRecordIds.map((i) => {
                let temp = state.rows.find((m) => i == m.id);
                if (temp) {
                    return {
                        partReqID: temp.id,
                        quantityIssuing: temp.qnIssuing,
                        status: +temp.issueStatusID,
                        partReqStockStatusId: temp.partReqStockStatusId || null,
                        partReqPartDescription: temp.desc,
                        partReqSupplier: temp.supplier
                    };
                }
            })
            // quantityIssueAgainstPartReqs: state.rows.map((r) => ({
            //     partReqID: r.id,
            //     quantityIssuing: r.qnIssuing,
            //     status: +r.issueStatusID,
            //     partReqStockStatusId: r.partReqStockStatusId || null,
            //     partReqPartDescription: r.desc
            // }))
        };
        let res1 = await postPhotosByPartReqID(state.rows);
        let res = await issuePartsQty(Id, data, state.technicianJobID, state.createdUserID);
        if (!res.success) {
            console.error(res);
        }
        showToast(res.message);
        if (res.success && fromStep) {
            mode == 'save' ? setBtnLoader((st) => ({ ...st, btn2: false })) : setBtnLoader((st) => ({ ...st, btn1: false }));
            setTimeout(() => {
                window.top.postMessage({ type: navigateToDashboard ? 'navigate-dashboard' : 'close-modal' }, '*');
            }, 1000);
        }
    };

    const TechBtnClick = (val) => {
        setState((st) => ({
            ...st,
            technicianJobID: val.technicianJobID,
            createdUserID: val.createdUserID,
            ownUser: val.ownUser == 'Y',
            showLoader: true
        }));
        pullWorkflowAndUpdateState();
    };

    useEffect(() => {
        fromStep && hideTopNavAndMenu();
        pullWorkflowAndUpdateState();
    }, []);

    const fieldChange = (val) => {
        setState((st) => ({
            ...st,
            ...val
        }));
    };

    const clearSig = (val) => {
        setState((st) => ({
            ...st,
            issuerSignature: null
        }));
    };

    const showModal = (value, date) => {
        setState((st) => ({
            ...st,
            showSignature: true,
            signature: value,
            sigDate: date
        }));
    };

    const closeDialog = () => {
        setState((st) => ({
            ...st,
            showSignature: false
        }));
    };
    const makeEmptyPhotos = (name) => {
        let changedArray = [...state.rows];
        let objIndex = state.rows.findIndex((x) => x.id === state.partId);
        changedArray[objIndex][name] = [];
        setState((st) => ({ ...st, rows: changedArray }));
    };

    const setPhotos = (name) => {
        setState((st) => ({ ...st, name }));
    };
    function CustomNoRowsOverlay() {
        return (
            <GridOverlay>
                <div>No parts available</div>
            </GridOverlay>
        );
    }
    return (
        <div style={{ maxWidth: '100%', marginTop: '10px auto', minHeight: '100vh' }}>
            {!fromStep && (
                <Breadcrumbs separator=">>" aria-label="breadcrumb">
                    <Link color="inherit" to={'/'}>
                        Home
                    </Link>
                    <Typography color="secondary" style={{ fontSize: '0.85rem' }}>
                        Part Requisitions
                    </Typography>
                </Breadcrumbs>
            )}
            <DialogTitles>
                <Grid container spacing={2}>
                    {state.technician.map((m) => (
                        <div className="tech-btn-group">
                            <Button className={state.createdUserID == m.createdUserID ? 'active-btn' : 'all-btn'} onClick={() => TechBtnClick(m)}>
                                {m.requestor}
                            </Button>
                        </div>
                    ))}
                </Grid>
            </DialogTitles>
            <DialogContents>
                <Grid container spacing={2}>
                    {data.showInfo && <PartReqOtherInfo data={data} onClose={() => setData((st) => ({ ...st, showInfo: false }))} />}
                    {state.showImage && (
                        <DialogComp title="Image Upload" onClose={() => onImagemodalClose(false)} fullWidth maxWidth="lg">
                            <PartReqPhotoModal
                                partId={state.partId}
                                onFormSubmit={() => pullWorkflowAndUpdateState()}
                                desc={state.desc}
                                onClose={onImagemodalClose}
                                onImageChange={setPhotos}
                                rows={state.rows}
                            />
                        </DialogComp>
                    )}
                    <Grid item xs={12} sm={6} style={{ fontSize: 21, textAlign: 'left' }}>
                        Registration Number:&nbsp; <b> {state.wipDetails.registration}</b>&nbsp; WIP No:&nbsp; <b> {state.wipDetails.wipNumber}</b>
                    </Grid>
                    {state.rows.length > 0 ? (
                        <>
                            <Grid item xs={4} sm={3}>
                                <SecondaryButton
                                    isBtnLoader={btnLoader.btn2}
                                    onClick={() => updateIssuedQuantity('save')}
                                    className="setFullWidth"
                                    disabled={!state.needQtyIssue && isAuditor}
                                >
                                    Save
                                </SecondaryButton>
                            </Grid>
                            <Grid item xs={4} sm={3}>
                                <SecondaryButton
                                    isBtnLoader={btnLoader.btn1}
                                    onClick={handelSubmit}
                                    className="setFullWidth"
                                    disabled={!state.needQtyIssue && !isAuditor}
                                >
                                    Submit
                                </SecondaryButton>
                            </Grid>
                            <Grid container item xs={12} justify="flex-end" style={{ padding: '0px 12px 0px 0px' }}>
                                <span className="mandatoryfields">{state.errors['isError']}</span>
                            </Grid>
                        </>
                    ) : null}
                    {state.showLoader ? (
                        <div
                            style={{
                                height: 'calc(100vh - 250px)',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                width: '100%'
                            }}
                        >
                            <CircularProgress style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                        </div>
                    ) : state.rows.length > 0 ? (
                        <>
                            <Grid
                                style={{
                                    height: `${windowHeight < 600 ? '400px' : 'calc(100vh - 342px)'}`,
                                    width: '100%',
                                    margin: '10px'
                                }}
                            >
                                <DataGrid
                                    className="data-grid-container"
                                    components={{
                                        NoRowsOverlay: CustomNoRowsOverlay
                                    }}
                                    headerHeight={50}
                                    rowHeight={50}
                                    hideFooter={true}
                                    columns={new_columns}
                                    rows={state.rows.filter((item) => item.isDeleted !== true)}
                                    disableColumnMenu={true}
                                    showColumnRightBorder={true}
                                    pageSize={100}
                                />
                            </Grid>
                            {/* <Grid item xs={12} md={4} lg={4}>
                                <CustomeSignPad
                                    onChange={fieldChange}
                                    content={'Technician Signature'}
                                    sign={state.requestorSignature}
                                    name="requestorSignature"
                                    hideBtn={true}
                                />
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                                <CustomeSignPad
                                    onChange={fieldChange}
                                    content={'Approver Signature'}
                                    sign={state.approverSignature}
                                    name="approverSignature"
                                    hideBtn={true}
                                />
                            </Grid> */}
                            {state.needQtyIssue && !isAuditor && (
                                <Grid item xs={12} md={4} lg={4}>
                                    <CustomeSignPad
                                        onChange={fieldChange}
                                        content={'Issuer Signature'}
                                        // sign={state.IssuerSignature}
                                        clearSig={clearSig}
                                        name="issuerSignature"
                                    />
                                    {/* <span className="mandatoryfields">{state.errors['issuerSignature']}</span> */}
                                </Grid>
                            )}
                        </>
                    ) : (
                        <Grid container item xs={12} style={{ height: 'calc(100vh - 342px)' }} justify="center" alignContent="center">
                            There are no part requisition requests for this job
                        </Grid>
                    )}
                </Grid>
            </DialogContents>
            {state.showSignature ? (
                <DialogComp title="Signature" maxWidth="sm" onClose={closeDialog}>
                    {state.signature ? (
                        <div style={{ position: 'relative' }}>
                            <div style={{ position: 'absolute', bottom: 10, right: 0 }}>{state.sigDate}</div>
                            <img src={state.signature} alt="my signature" style={{ border: '1px solid', height: 178, width: '100%' }} />
                        </div>
                    ) : (
                        <h1>No Signature found</h1>
                    )}
                </DialogComp>
            ) : null}
            {state.showConfirmModal ? (
                <DialogComp title="Part Requisitions" onClose={() => setState((st) => ({ ...st, showConfirmModal: false }))} maxWidth="sm">
                    <ConfirmationModal
                        message={`By submitting you will not be able to make any further changes to the form and it transferred to Requestor. If you need to make further changes please click cancel and select the save option.`}
                        handleSubmit={() => updateIssuedQuantity('submit')}
                        handleCancel={() => setState((st) => ({ ...st, showConfirmModal: false }))}
                    />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default PartRequisitionsIssueQuantity;
