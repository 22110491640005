import React, { useState, useEffect, useContext } from 'react';
import DataGridComp from '../../../Core/Grid';
import DialogComp from '../../../Core/Controls/Dialog/DialogComp';
import BranchModal from './BranchModal';
import BreadCrumbs from '../../../Core/Controls/Breadcrumb/index';
import Tooltip from '@material-ui/core/Tooltip';
import { IconButton } from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { getAllBranch } from '../../../Core/Service/branch-screen-service';
import '../commonStyle.scss';
import BranchTargetScreen from './BranchTarget';
import AppContext from '../../../App/AppContext';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import { Backup, Link } from '@material-ui/icons';
import UpdateBranchCodesModal from './BranchCodeModal';

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Branch', active: true }
];
const styles = {
    gridImage: { width: 60, height: 'auto', backgroundSize: 'cover', marginTop: '5%' },
    gridImageContainer: { width: '100%', textAlign: 'center' }
};

function Braches(props) {
    let config = window._ENV_;
    let checkSite = window.location.host.includes('Nicol') ? true : false;

    const [state, setState] = useState({
        branchList: [],
        branchName: null,
        branchCode: '',
        id: null,
        loader: true,
        open: false
    });

    const { portalSettings } = useContext(AppContext);

    const stampRender = (data) => {
        let isBase64Image = data.row.branchStampImage.includes('base64');
        return (
            <div style={styles.gridImageContainer}>
                {data.row.branchStampImage ? (
                    <img
                        src={isBase64Image ? data.row.branchStampImage : 'data:image/png;base64,' + data.row.branchStampImage}
                        alt={'stamp'}
                        style={styles.gridImage}
                    />
                ) : null}
            </div>
        );
    };

    useEffect(async () => {
        pullData();
    }, []);

    const pullData = async () => {
        let res = await getAllBranch();
        setState((state) => ({
            ...state,
            branchList: res.data
        }));
    };

    const btnHandler = (params) => {
        setState((state) => ({ ...state, open: true, id: params.id, branchName: params.branchName, branchCode: params.branchCode }));
    };

    const targetBtnHandler = (params) => {
        setState((state) => ({ ...state, openTargetModal: true, branchCode: params.branchCode, branchName: params.branchName }));
    };

    const codesBtnHandler = (params) => {
        setState((state) => ({ ...state, openCodeModal: true, id: params.id, branchCode: params.branchCode, branchName: params.branchName }));
    };

    const ActionButtons = (params) => {
        return (
            <div>
                <Tooltip title="Update Stamps" aria-label="add">
                    <IconButton size="small" onClick={() => btnHandler(params.value.row)}>
                        {/* <EditOutlinedIcon color="secondary" fontSize="small" /> */}
                        <Backup color="secondary" fontSize="small" />
                    </IconButton>
                </Tooltip>
                {portalSettings.portalSettingReportingEnabled && checkSite && (
                    //Show this button to only Nicol site
                    <Tooltip title="Set Target" aria-label="add">
                        <IconButton size="small" onClick={() => targetBtnHandler(params.value.row)}>
                            <TrackChangesIcon color="secondary" fontSize="small" />
                        </IconButton>
                    </Tooltip>
                )}
                <Tooltip title="Set Branch Codes" aria-label="add">
                    <IconButton size="small" onClick={() => codesBtnHandler(params.value.row)}>
                        <Link color="secondary" fontSize="small" />
                    </IconButton>
                </Tooltip>
            </div>
        );
    };

    const columns = [
        {
            field: '',
            headerName: 'Action',
            sortable: false,
            width: 100,
            renderCell: (params) => <ActionButtons value={params} />
        },
        { field: 'branchName', headerName: 'Branch Name', flex: 2 }
        // { field: 'branchStampImage', headerName: 'Stamps', flex: 2, renderCell: stampRender }
    ];

    const title = state.id ? 'Upload Stamps' : 'Add Stamps';

    const closeModal = (res) => {
        setState((state) => ({ ...state, open: false, openTargetModal: false, openCodeModal: false }));
        res && pullData();
    };

    return (
        <div className="screen">
            <BreadCrumbs crumbs={crumbs} />
            <DataGridComp
                columns={columns}
                rows={state.branchList}
                headerHeight={40}
                rowHeight={80}
                isRowHovered={true}
                enableSearch={true}
                loadingData={state.loader}
                onBtnClick={btnHandler}
                offset={260}
            />
            {state.open ? (
                <DialogComp title={title} onClose={() => closeModal(false)} fullWidth maxWidth="md">
                    <BranchModal id={state.id} branchName={state.branchName} branchCode={state.branchCode} onClose={() => closeModal(true)} />
                </DialogComp>
            ) : null}
            {state.openTargetModal ? (
                <DialogComp title={`Set Target for - ${state.branchName}`} onClose={() => closeModal(false)} fullWidth maxWidth="xl" fullScreen>
                    <BranchTargetScreen branchCode={state.branchCode} branchName={state.branchName} />
                </DialogComp>
            ) : null}
            {state.openCodeModal ? (
                <DialogComp title={`Edit Branch Codes for - ${state.branchName}`} onClose={() => closeModal(false)} maxWidth="md" fullWidth>
                    <UpdateBranchCodesModal branchID={state.id} onClose={() => closeModal(false)} />
                </DialogComp>
            ) : null}
        </div>
    );
}
export default Braches;
