import React from 'react';
import moment from 'moment';
import { Grid, FormLabel, Breadcrumbs, InputLabel } from '@material-ui/core';
import { SingelSelect, TextBox } from '../../../../../Core/FormInput';
// import NestedGijgoGrid from './../../../../Core/Controls/GijgoGrid/nestedGijgoGrid';
import { Link } from 'react-router-dom';
// import BreadCrumbs from './../../../../Core/Controls/Breadcrumb';
import NestedGijgoGrid from '../../../../../Core/Controls/GijgoGrid/nestedGijgoGrid';
import BreadCrumbs from '../../../../../Core/Controls/Breadcrumb/index';
import formatters from '../../../../../Core/Grid/inputFormatter';
import { useState } from 'react';
import { useEffect } from 'react';
import { getReportsSalesDropdownsResolveData } from '../../../../../Core/Service/reportService';
import { getSalesVehicleDropdown } from '../../../../../Core/Service/salesDasboard-service';

var monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
let addCommas = (val) => {
    return val ? `${val.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}` : '0.00';
};

const baseColumns = [
    { title: 'SBN', field: 'sn', width: 50, sortable: true },
    // { title: 'Comm Number', field: 'cn', width: 70, sortable: true },
    { title: 'Reg Number', field: 'rn', width: 80, sortable: true },
    {
        title: 'Vehicle Description',
        field: 'vd',
        width: 170
    },

    {
        title: 'Sold By',
        field: 'sp',
        width: 100,
        sortable: true
    },
    {
        title: 'Latest Profit',
        field: 'latestProfitValue',
        renderer: formatters.CurrencyThousandSeparator,
        width: 85,
        sortable: true,
        align: 'right'
    },
    // {
    //     title: 'Progress',
    //     field: 'pd',
    //     width: 80,
    //     sortable: true,
    //     isDropDown: true,
    //     listIdFromAPIResponse: 'progressCodes',
    //     dropDownValueField: 'id',
    //     dropDownLableField: 'name'
    // },

    {
        title: 'Customer',
        field: 'cust',
        width: 130,
        sortable: true
    },
    {
        title: 'Reg Date',
        field: 'regDate',
        width: 80,
        renderer: formatters.MonthShortFormatter
    },
    {
        title: 'Invoice',
        field: 'invoice',
        width: 80
    },

    {
        title: 'Invoice Date',
        field: 'id',
        width: 80,
        renderer: formatters.MonthShortFormatter
    },
    {
        title: 'Stock Days',
        field: 'd',
        width: 80,
        sortable: true,
        // renderer: formatters.MonthShortFormatter,
        cssClass: 'text-center'
    },
    {
        title: 'Order Type',
        field: 'ot',
        width: 100
    },
    // {
    //     title: 'Model',
    //     field: 'rmac',
    //     width: 70,
    //     sortable: true,
    //     isDropDown: false
    //     // listIdFromAPIResponse: 'modelRecords',
    //     // dropDownValueField: 'name',
    //     // dropDownLableField: 'name'
    // },

    // {
    //     title: 'Franchise',
    //     field: 'f',
    //     width: 80,
    //     sortable: true,
    //     isDropDown: false
    //     // listIdFromAPIResponse: 'modelAnalysisCodes',
    //     // dropDownValueField: 'name',
    //     // dropDownLableField: 'name'
    // },
    {
        title: 'Branch',
        field: 'bn',
        width: 70,
        sortable: true,
        isDropDown: true,
        listIdFromAPIResponse: 'branchList',
        dropDownValueField: 'name',
        dropDownLableField: 'name'
    },
    {
        title: 'Status',
        field: 'accStatus',
        width: 80
    }

    // {
    //     title: 'Cost',
    //     field: 'cost',
    //     renderer: formatters.CurrencyThousandSeparator,
    //     width: 70,
    //     sortable: true,
    //     align: 'right'
    // },
    // {
    //     title: 'Order Date',
    //     field: 'od',
    //     width: 70,
    //     sortable: true,
    //     renderer: formatters.MonthShortFormatter,
    //     align: 'right'
    // },
    // {
    //     title: 'Build Date',
    //     field: 'bd',
    //     width: 70,
    //     sortable: true,
    //     renderer: formatters.MonthShortFormatter,
    //     align: 'right'
    // },
    // {
    //     title: 'Cons Date',
    //     field: 'cd',
    //     width: 90,
    //     sortable: true,
    //     renderer: formatters.MonthShortFormatter,
    //     align: 'right'
    // },
    // {
    //     title: 'Est Date',
    //     field: 'EstimDeliveryDate',
    //     renderer: formatters.MonthShortFormatter,
    //     width: 70,
    //     sortable: true,
    //     align: 'right'
    // }
];

const detailTemplate = () => {
    return (
        "<div style='width:100%'><table style='width:100%;'>" +
        "<tr style='background-color:#DCDCDC;'>" +
        "<th style='font-weight:750; text-align:left;'>Franchise</th >" +
        "<th style='font-weight:750; text-align:left;'>Comm Number</th >" +
        "<th style='font-weight:750; text-align:left;'>Order Date</th >" +
        "<th style='font-weight:750; text-align:left;'>Build Date</th>" +
        "<th style='font-weight:750; text-align:left;'>Cons Date</th>" +
        "<th style='font-weight:750; text-align:left;'>Est Date</th>" +
        "<th style='font-weight:750; text-align:left;'>Est Profit</th></tr>" +
        '<tr><td>{f}</td><td>{cn}</td><td>{od}</td><td>{bd}</td><td>{cd}</td><td>{edd}</td><td>£{estimatedProfit}</td></tr></div>'
    );
};

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Sales', active: true },
    { name: 'Registration Report', active: true }
];

// let startDate = ;

const RegistrationReports = (props) => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    let typeID = params.get('type') || '';
    let modelID = params.get('model') || '';
    let startDateParams = params.get('startDate') || '';
    let endDateParams = params.get('endDate') || '';
    let isElectric = params.get('isElectric') || '';
    const [state, setState] = useState({
        EndDate: endDateParams || moment().format('YYYY-MM-DD'),
        StartDate: startDateParams || moment().startOf('month').format('YYYY-MM-DD'),
        categoriesId: typeID ? (typeID === '1' ? { id: 1, name: 'VAN' } : { id: 2, name: 'TRUCK' }) : {},
        modelAnalysisCodeId: {},
        categoriesList: [],
        modelAnalysisCodeList: [],
        filterAnalysiscodelist: []
    });
    const handleInput = (e) => {
        const { name, value } = e.target;
        setState((st) => ({
            ...st,
            [name]: value
        }));
    };

    useEffect(async () => {
        let res = await getSalesVehicleDropdown();

        if (res.success) {
            setState((st) => {
                let newSt = { ...st };
                newSt.categoriesList = res.data?.category || [];
                newSt.modelAnalysisCodeList = res.data?.modelAnalysisCode || [];
                // if (PramCategoryType) {
                //     newSt.categoriesId = res.data?.category.find((r) => r.id == PramCategoryType);
                // }
                return newSt;
            });
        }
    }, []);

    useEffect(async () => {
        setState((st) => {
            let newSt = { ...st };
            if (JSON.stringify(state.categoriesId) !== '{}') {
                newSt.filterAnalysiscodelist = state.modelAnalysisCodeList.filter((k) => k.cid === state.categoriesId?.id);
            } else {
                newSt.filterAnalysiscodelist = [...st.modelAnalysisCodeList];
            }
            return newSt;
        });
    }, [state.categoriesId, state.modelAnalysisCodeList]);

    useEffect(async () => {
        if (modelID) {
            setState((st) => {
                let newSt = { ...st };
                newSt.modelAnalysisCodeId = state.modelAnalysisCodeList.find((k) => k.id === modelID) || {};
                return newSt;
            });
        }
    }, [modelID, state.modelAnalysisCodeList]);

    const singleSelectChange = (nm) => (ev, val) => {
        setState((st) => {
            let newSt = { ...st, [nm]: val };
            return newSt;
        });
    };

    const baseUrl = `Sales/RegistrationReport?StartDate=${state.StartDate}&EndDate=${state.EndDate}&VehicleCategoryID=${state.categoriesId?.id || ''}&rmac=${
        state.modelAnalysisCodeId?.id || ''
    } &isElectric=${isElectric}`;

    return (
        <div className="report-screen-container">
            <BreadCrumbs crumbs={crumbs} />
            <Grid container spacing={3}>
                <Grid item xs={6} md={4} lg={3}>
                    <InputLabel shrink>Start Date</InputLabel>
                    <TextBox type="date" name="StartDate" value={state.StartDate} onChange={handleInput} />
                </Grid>
                <Grid item xs={6} md={4} lg={3}>
                    <InputLabel shrink>End Date</InputLabel>
                    <TextBox type="date" name="EndDate" value={state.EndDate} onChange={handleInput} />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <InputLabel shrink>Vehicle Categories</InputLabel>
                    <SingelSelect options={state.categoriesList || []} value={state.categoriesId} onChange={singleSelectChange('categoriesId')} />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <InputLabel shrink>Model</InputLabel>
                    <SingelSelect
                        options={state.filterAnalysiscodelist || []}
                        value={state.modelAnalysisCodeId}
                        onChange={singleSelectChange('modelAnalysisCodeId')}
                    />
                </Grid>
            </Grid>
            <NestedGijgoGrid
                dataConverter={(res) => ({
                    records: res?.data?.list,
                    total: res?.data?.total
                })}
                columns={baseColumns}
                getUrl={baseUrl}
                displayPagination={true}
                detailTemplate={detailTemplate}
                downloadName={'Registration Report'}
                dropDownListProvider={getReportsSalesDropdownsResolveData}
            />
        </div>
    );
};

export default RegistrationReports;
