import {get, post, put } from './http-calls';

export const addReportingCodes = async(id, description) => {
    let res = await post(`/api/ReportingCodes`, {
        ReportingCode: id,
        ReportCodeDescription: description,
        Mode: "A",
    }, { useAuthToken: true });
    return res;
}

export const updateReportingCodes = async(id, description) => {
    let res = await post(`/api/ReportingCodes`, {
        ReportingCode: id,
        ReportCodeDescription: description,
        Mode: "U",
    }, { useAuthToken: true });
    return res;
};

export const getReportingCodes = async() => {
    let res = await get('/api/ReportingCodes', { useAuthToken: true });
    return res;
}